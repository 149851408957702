import "./styles.scss";

import React from "react";

function PerkCard({ icon, title, description, alt }) {
  return (
    <div className="perk-card">
      <div className="perk-card__icon">
        <img className="" src={icon} alt={alt} />
      </div>
      <h4 className="perk-card__title">{title}</h4>
      <p className="perk-card__description">{description}</p>
    </div>
  );
}

export default PerkCard;
