import "./styles.scss";

import React from "react";

import ArrowIcon from "@icons/arrow.svg";

function ArrowButton({ variant = "black", rotate = 0, onClick = undefined }) {
  return (
    <button
      className={
        variant === "white"
          ? "arrow-button arrow-button--white"
          : "arrow-button"
      }
      type="button"
      onClick={onClick}
      style={{ transform: `rotate(${rotate}deg)` }}
    >
      <ArrowIcon width={34} height={26} />
    </button>
  );
}

export default ArrowButton;
