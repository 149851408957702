import * as React from "react";
import { graphql } from "gatsby";

import OpinionCarousel from "@components/OpinionCarousel";
import Breadcrumbs from "@components/Breadcrumbs";
import Seo from "@components/Seo";

import PerkCard from "@page_components/why-us/PerkCard";

function WhyUsPage({ data }) {
  const { seo } = data?.seo_data?.nodes[0];

  const breadcrumbs_data = [{ name: "Dlaczego my?", href: `/dlaczego-my` }];
  const { perks } = data.perks.nodes[0].pageWhyUs;

  return (
    <>
      <Seo title={seo.title} description={seo.metaDesc} />
      <div className="why-us">
        <Breadcrumbs data={breadcrumbs_data} />
        <div className="container">
          <main>
            <h1> {data.perks.nodes[0].title} </h1>
            <div className="why-us__perks">
              {perks.map((perk) => (
                <PerkCard
                  title={perk.title}
                  description={perk.description}
                  icon={perk.icon.sourceUrl}
                  alt={perk.icon.altText}
                  key={perk.title}
                />
              ))}
            </div>
          </main>
        </div>

        <section className="why-us__opinions">
          <OpinionCarousel variant="black" />
        </section>
      </div>
    </>
  );
}

export default WhyUsPage;

export const query = graphql`
  {
    perks: allWpPage(filter: { title: { eq: "Dlaczego my?" } }) {
      nodes {
        title
        pageWhyUs {
          perks {
            title
            description
            icon {
              sourceUrl
              altText
            }
          }
        }
      }
    }
    seo_data: allWpPage(filter: { title: { eq: "Dlaczego my?" } }) {
      nodes {
        seo {
          title
          metaDesc
        }
      }
    }
  }
`;
