import "./styles.scss";

import React from "react";
import { Link } from "gatsby";

import GoogleLogo from "@icons/google.svg";
import BooksyLogo from "@icons/booksy.svg";
import FacebookLogo from "@icons/facebook-logo.svg";
import StarIcon from "@icons/star.svg";
import HalfStarIcon from "@icons/half-star.svg";

function Opinion({
  user,
  origin,
  contents,
  rate,
  uri,
  color,
  variant = "default",
}) {
  const limit_chars = variant === "card" ? 200 : 300;

  const getLogoService = (name) => {
    switch (name) {
      case "Google":
        return (
          <GoogleLogo
            width={variant === "card" ? 85 : 52}
            height={variant === "card" ? 34 : 17}
          />
        );
      case "Booksy":
        return (
          <BooksyLogo
            width={variant === "card" ? 100 : 65}
            height={variant === "card" ? 32 : 20}
          />
        );
      case "Facebook":
        return (
          <FacebookLogo
            width={variant === "card" ? 85 : 75}
            height={variant === "card" ? 25 : 22}
          />
        );
      default:
        return null;
    }
  };

  const cutDescription = (text) => {
    return `${text.slice(0, limit_chars)}...`;
  };

  const getRateStars = (number) => {
    const stars_list = [];

    for (let i = 1; i <= number; i++) {
      stars_list.push(<StarIcon />);
    }

    if (number % 5) stars_list.push(<HalfStarIcon />);

    return stars_list;
  };

  const description =
    contents.length >= limit_chars ? cutDescription(contents) : contents;

  return (
    <div className={variant === "card" ? "opinion opinion--card" : "opinion"}>
      <h5 className="opinion__title">{user}</h5>
      {variant !== "card" && (
        <span
          className={
            color === "black" ? "opinion__origin--black" : "opinion__origin"
          }
        >
          Opinia z {getLogoService(origin)}
        </span>
      )}

      {variant === "card" && (
        <>
          <div className="opinion__stars">{getRateStars(rate)}</div>

          <div className="opinion__logo">{getLogoService(origin)}</div>
        </>
      )}

      <p className="opinion__description">
        {description}
        {variant === "card" && (
          <Link className="opinion__more-link" to={uri}>
            Więcej
          </Link>
        )}
      </p>
    </div>
  );
}

export default Opinion;
