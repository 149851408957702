import "./styles.scss";

import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Slider from "react-slick";

import { useLocale } from "@context/locale";

import ArrowButton from "@components/ArrowButton";
import Opinion from "@components/Opinion";
import Button from "@components/Button";

function OpinionCarousel({ variant = "white" }) {
  const { t } = useLocale();
  const settings_slider = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,

    className: "opinion-slider",
    nextArrow: (
      <ArrowButton variant={variant} className="opinion-slider__arrow" />
    ),
    prevArrow: (
      <ArrowButton
        variant={variant}
        rotate={180}
        className="opinion-slider__arrow"
      />
    ),
  };
  const data = useStaticQuery(graphql`
    {
      allWpOpinion(limit: 6) {
        nodes {
          opinion {
            opinionDescription
            opinionOrigin
            opinionAuthor
          }
          id
        }
      }
    }
  `);
  const limit_opinions = 6;
  const opinions_data = data.allWpOpinion.nodes;

  return (
    <div
      className={
        variant === "black"
          ? "opinion-carousel opinion-carousel--black"
          : "opinion-carousel"
      }
    >
      <div className="container">
        <h2>{t("Opinie naszych klientów")}</h2>
        <h4>{t("Dlaczego warto skorzystać z naszych usług")}</h4>
        <Slider {...settings_slider}>
          {opinions_data.map((item, index) => {
            if (index < limit_opinions)
              return (
                <Opinion
                  user={item.opinion.opinionAuthor}
                  origin={item.opinion.opinionOrigin}
                  contents={item.opinion.opinionDescription}
                  color={variant}
                  key={item.id}
                />
              );

            return null;
          })}
        </Slider>
        <Button
          href={t("opinions_link")}
          variant={variant}
          className="opinion-carousel__button"
        >
          {t("Zobacz więcej")}
        </Button>
      </div>
    </div>
  );
}

export default OpinionCarousel;
